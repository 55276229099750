.pausedRow td {
	background-color: #f2f2f2 !important;
}

.pausedRow:hover td{
	box-shadow: #b7b7b7 0px 0px 0px 0px;
	background-color: #f2f2f2;
	/*color: red !important;*/
}

/*
.activeRow td {
	background-color: white !important;
}
*/