.toggle-switch.wide {
  width: 120px;
  height: 40px;
  background-color: #edf2fa;
  border-radius: 20px;
  border: 2px solid #d05cfe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  padding: 0px;
  font-family: Arial, sans-serif;
  user-select: none;
  transition: background-color 0.3s ease;
}

.toggle-switch:hover {
  background-color: #ccc;
}

.toggle-circle {
  width: 40px !important;
  height: 40px !important;
  background-color: #dbafed;
  border: 2px solid #d05cfe;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -2px;
  transition: transform 0.3s ease;
  z-index: 1;
}

.toggle-circle.viewmode-1 {
  transform: translateX(0px); /* Aligns circle on the left */
}

.toggle-circle.viewmode-2 {
  transform: translateX(80px); /* Aligns circle on the right */
}

.toggle-label {
  flex-grow: 1;
  font-size: 14px;
  color: #555;
  text-align: center;
  z-index: 0; /* Text is below the circle */
}

.toggle-label.viewmode-1 {
  margin-left: 45px;
  margin-right: 10px;
}

.toggle-label.viewmode-2 {
  margin-right: 45px;
  margin-left: 10px;
}

