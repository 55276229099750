/* LanguageSwitcher.css */

.my-language-dropdown .btn-primary {
  background-color: #005f73 !important;
  border: none;
}

.my-language-dropdown .btn-primary:hover {
  background-color: #0a9396 !important;
}

.my-language-dropdown .btn-primary:active {
  background-color: #0a9396 !important;
}

.my-language-dropdown .dropdown-menu {
  border-radius: 4px;
  padding: 0;
}

.my-language-dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}

.my-language-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #e6f4f1;
}
