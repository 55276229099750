/* VideoList.css */
.video-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
}

.video-item {
  width: calc(20% - 16px); /* Adjust percentage based on how many videos per row you want */
  margin-bottom: 16px;
  text-align: left;
}

.video-thumbnail {
  width: 100%;
  border-radius: 8px;
}

.video-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.video-tags {
  margin-top: 4px;
}

.video-tag {
  display: inline-block;
  background-color: #f1f1f1;
  color: #555;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}