.custom-dropdown-toggle {
  display: flex !important;
  flex-direction: column; /* Stack vertically on narrow screens */
  align-items: flex-start;
  white-space: nowrap; /* Prevent breaking words */
}

@media (min-width: 350px) { /* Adjust this value according to your design */
  .custom-dropdown-toggle {
    flex-direction: row !important; /* Stack horizontally on wide screens */
    white-space: normal; /* Allow normal wrapping when wide */
  }
}

@media (min-width: 350px) { /* Adjust this value according to your design */
  .input-text-col {
    padding: 0% 10%;
  }
}

@media (max-width: 576px) {
  .custom-dropdown-button{
    display: flex;
    justify-content: center;
  }
}

.dropdown-label {
  margin-right: 0.5rem; /* Add some spacing between label and value */
  white-space: nowrap; /* Prevent breaking within this text */
}

.dropdown-value {
  white-space: nowrap; /* Prevent breaking within this text */
}

.custom-dropdown-button{

}