/* styles.css */
form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-top: 10px;
}

/*
input[type='text'],
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
*/

button {
  margin-right: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}