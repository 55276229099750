
.datainput-row {
  margin-bottom: 0.4rem;
}

.datainput-row .toast-body {
  padding: 5px 0px;
  min-height: 7rem;
}

.flex-responsive {
  display: flex;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .flex-responsive {
    justify-content: flex-end;
  }
}


