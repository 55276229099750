
/*---- Hakka Font ----*/

@font-face {
  font-family: 'TauhuOo';
  src: url('./assets/fonts/TauhuOo20.05-Regular.woff2') format('woff2'),
       url('./assets/fonts/TauhuOo20.05-Regular.woff') format('woff'),
       url('./assets/fonts/TauhuOo20.05-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Noto Sans', 'TauhuOo', sans-serif !important;
}

/*---- Hakka Font ----*/




/*---- Glow Button ----*/

/* Base button styling */
.glow-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  /*background: #333;*/
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.3s ease;
}

/* Glow effect using ::before */
.glow-button::before {
  content: "";
  position: absolute;
  top: -10%;
  left: -120%; /* Start off-screen */
  width: 120%;
  height: 120%;
  background: linear-gradient(140deg, 
    transparent, 
    rgba(255, 255, 255, 0), 
    rgba(255, 255, 255, 0.1), 
    rgba(255, 255, 255, 0.6), 
    rgba(255, 255, 255, 0.1), 
    rgba(255, 255, 255, 0), 
    transparent
  );
  transition: opacity 0.3s ease;
  animation: glow-animation 8s infinite;
}

.glow-button.glow-slow::before {
	animation: glow-animation-slow 8s infinite;
}

.glow-button.glow-slow.glow-frequent::before {
	animation: glow-animation-slow 4s infinite;
}

/* Keyframes for the glow animation */
/* Start off-screen on the left */
/* Move to the right side */
/* Stay on the right side for the pause duration */
@keyframes glow-animation {
  0% {
    left: -100%;
  }
  25% {
    left: 140%;
  }
  100% {
    left: 140%;
  }
}

@keyframes glow-animation-slow {
  0% {
    left: -100%;
  }
  90% {
    left: 140%;
  }
  100% {
    left: 140%;
  }
}

/* Hover effects to enhance the glow */
.glow-button:hover::before {
  opacity: 1;
}


/*---- Glow Button ----*/



