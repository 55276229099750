

.databases-drags.static {
  transform: none !important;
}

.databases-drags .card {
  opacity: 50%;
}

.databases-drags.static .card {
  opacity: 100%;
}

.profile-pic {
	width: 10rem; /* or any other size */
	height: 10rem; /* should be the same as width to create a circle */
	border-radius: 50%; /* this makes it round */
	object-fit: cover; /* this ensures that the image covers the area and is not stretched */
	display: block; /* replace with flex or grid if needed */
	margin: 0 auto; /* this centers the image in its container if it's a block element */
	overflow: hidden; /* this ensures that the image does not break the round shape */
}

.avatar-bigger {
	height: 5rem !important;
	width: 5rem !important;
}

/* For extra large devices (xl - screens ≥1200px) */
@media (min-width: 1200px) {
  .custom-item-per-page-col {
  	align-items: flex-end;
  	justify-content: center;
  }
}

/* For large devices (lg - screens ≥992px and <1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-sorting-col {
  	justify-content: flex-end;
  }
}

/* For medium devices (md - screens ≥768px and <992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-sorting-col {
  	justify-content: flex-end;
  }
}

/* For small devices (sm - screens ≥576px and <768px) */
@media (min-width: 576px) and (max-width: 767.98px) {
	.custom-sorting-col {
  	justify-content: flex-start;
  }
  .custom-item-per-page-col {
  	align-items: flex-end;
  }
}

/* For xs devices (xs - screens <576) */
@media (max-width: 575.98px) {
  .custom-item-per-page-col {
  	align-items: flex-start;
  	margin-top: 0.5rem !important;
  }
}



