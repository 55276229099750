
.custom-page-link{
  text-decoration: underline !important;
}

.folder-item {
  outline: none; /* Default state */
}

.folder-item:hover {
  outline: 2px solid #999; /* Highlight on hover */
}

.folder-item.selected {
  outline: none; /* Optional: Highlight selected */
}

.folder-item.selected:hover {
  outline: 2px solid #7909a5; /* Optional: Highlight selected */
}

.folder-item.folder-item-second.selected:hover {
  outline: 2px solid #3581be; /* Optional: Highlight selected */
}

.project-item-wrapper {
  transition: opacity 0.1s ease;
}

.project-item-wrapper.dropped-item {
  opacity: 0.3;
}



