.date-picker-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date-picker-container label {
  font-weight: bold;
  margin-right: 1rem;
  color: #333;
}

.datepicker-label {
  min-width: 5rem;
  text-align: center;
}

.custom-datepicker {
  margin-top: 0px !important;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.custom-datepicker:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}