.element-text {
	background: transparent;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.element-gray {
	background: lightgrey;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-gray {
	background: lightgrey;
}

.element-green {
	background: #c2e09b;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-green {
	background: #c2e09b;
}

.element-yellow {
	background: #ffeb9d;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-yellow {
	background: #ffeb9d !important;
}

.element-blue {
	background: #b5deff;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-blue {
	background: #b5deff !important;
}

.element-bluegray {
	background: #bccfd7;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-bluegray {
	background: #bccfd7 !important;
}

.element-red {
	background: #fcc2b0;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-red {
	background: #fcc2b0 !important;
}

.element-azure {
	background: #90e6ed;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-azure {
	background: #90e6ed;
}

.element-orange {
	background: #ffd8b1;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-orange {
	background: #ffd8b1;
}

.element-purple {
	background: #e3d5ff;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.option-purple {
	background: #e3d5ff;
}

.element-transparent {
	background: transparent;
  padding: 0.3rem;
  margin-right: 0.2rem;
  border-radius: 0.2rem;
}

.left-part {
	border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.right-part {
	border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}



