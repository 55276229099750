/* VideoManager.css */
.video-manager {
  max-width: 600px;
  margin: 0 auto;
}

.video-manager form {
  display: flex;
  flex-direction: column;
}

.video-manager form div {
  margin-bottom: 10px;
}

.video-manager button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.video-manager ul {
  list-style-type: none;
  padding: 0;
}

.video-manager ul li {
  margin-bottom: 10px;
}

.video-manager ul li button {
  margin-left: 10px;
  padding: 5px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.video-item {
  width: calc(20% - 16px); /* Adjust percentage based on how many videos per row you want */
  margin-bottom: 16px;
  text-align: left;
}

.video-thumbnail {
  width: 100%;
  border-radius: 8px;
}